import React, {useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker} from 'antd'

import FormEuro from './form_euro'

interface Props {
    handleChangeNameValue: any
    data: any
    inputName: any
}

function OrtodonciaForm(props: Props) {
    const {
        handleChangeNameValue,
        inputName,
        data
    } = props
    const inputAmount = useRef<HTMLInputElement>(null);
    const inputAceptado = useRef<HTMLInputElement>(null);
    const section="ortodoncia";

    const {tiposOrtodonciasLists, doctorLists} = useSelector( (state:any)=>state.admins)

    const handleChange = (inputCamp:any, value:any) => {
        sessionStorage.setItem(section, inputCamp);

        // if(inputCamp === 'tipo_ortodoncia'){
        //     let ortodoncia = tiposOrtodonciasLists.filter( (item:any)=>item.id === value)
        //     handleChangeNameValue(inputName, inputCamp, ortodoncia[0])
        // }   

        // if(inputCamp === 'doctor'){
        //     let doctor = doctorLists.filter( (item:any)=>item.id === value)
        //     handleChangeNameValue(inputName, inputCamp, doctor[0])
        // }

        // if(inputCamp !== 'tipo_ortodoncia' && inputCamp !== 'doctor') 
        

        handleChangeNameValue(inputName, inputCamp, value)
    }
    useEffect(() => {
        const last = sessionStorage.getItem(section);

        if(last == "presupuesto") {
            if (inputAmount.current) {
                inputAmount.current.focus();
            }
        }else if(last=="aceptado"){
            if (inputAceptado.current) {
                inputAceptado.current.focus();
            }
        }
    }, []);
    const {Option} = Select
    const filters = useSelector((state: any)=> state.Filters)
    return (
        <div className="wrapper">
            <Row style={{display: 'grid', gridGap: '.5em', gridTemplateColumns: '50% 50%'}}>

                <FormEuro
                    label='Presupuesto'
                    placeholder='Escribir cantidad'
                    value={data.presupuesto}
                    name='presupuesto'
                    handleChange={(value:any)=>handleChange('presupuesto', value)}
                    ref={inputAmount}
                />

                <FormEuro 
                    label='Aceptado'
                    placeholder='Escribir cantidad'
                    value={data.aceptado}
                    name='aceptado'
                    handleChange={(value:any)=>handleChange('aceptado', value)}
                    ref={inputAceptado}
                    />

                <Col span={24}>
                    <Form.Item label='Doctor' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                        <Select 
                            onChange={(value:any)=>handleChange('empleado_id', value)}
                            value={data.empleado_id}
                            style={{width: '100%'}} >
                            {
                                doctorLists.map( (item:any, id:any)=>
                                    (filters==null || filters.centro==null || !filters.centro || item.centro_id==filters.centro)?(
                                        <Option key={id} value={item.id}>{item.nombre} {item.apellidos}</Option>

                                    ):null)
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label='Tipo de ortodoncia' name='tipo_ortodoncia' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                        <Select 
                            onChange={(value:any)=>handleChange('tipo_ortodoncia', value)} 
                            style={{width: '100%'}}
                            value={data.tipo_ortodoncia ? data.tipo_ortodoncia.nombre : ''}>
                            {
                                tiposOrtodonciasLists.map( (item:any, id:any)=>(
                                    <Option key={id} value={item.id}>{item.nombre}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
    </div>
    )
}

export default OrtodonciaForm
