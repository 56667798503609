/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Layout, Select, DatePicker, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import moment from "moment";

import "./filter.scss";

import {
  getResumen,
  setUseOldDates,
  getObjetivoPrevision,
} from "../../../redux/global/actions";
import {
  setAsesor,
  setClinica,
  setConsultor,
  setEspecialidad,
  setDoctor,
  setCentro,
} from "../../../redux/filters/actions";
import { useLocation } from "react-router-dom";

interface Props {}

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

function IndexSection(props: Props) {
  const {} = props;
  const { Content } = Layout;
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const location = useLocation();
  const dispatch = useDispatch();

  const filters = useSelector((state: any) => state.Filters);
  const { user } = useSelector((state: any) => state.auth);
  const { date, lastDates } = useSelector((state: any) => state.ResumenGlobal);
  
  const dateInputRef: any = useRef(null);
  const [selectedName, setSelectedName] = useState("");
  const [useChildren, setUseChildren] = useState(false);

  const [currentclinica, setCurrentClinica] = useState(null);

  const [dateValue, setDateValue] = useState<any>(null);

  const {
    especialidadesLists,
    consultorLists,
    clinicaLists,
    directorLists,
    doctorLists,
    centrosLists,
  } = useSelector((state: any) => state.admins);

  const onClick = () => {
    setUseChildren(!useChildren);
  };

  const handleChange = (value: any) => {
    if (value) {
      let startDate = value[0].set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      let endDate = value[1].set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      });
      dispatch(
        getResumen({
          starts: startDate,
          ends: endDate,
          last: lastDates,
          filters: filters,
        })
      );
      setTimeout(() => onClick(), 400);
    }
  };

  const handleSelect = (value: any, formName: any) => {
    if (formName === "checkbox") {
      dispatch(setUseOldDates(value.target.checked));
      dispatch(
        getResumen({
          ...date,
          last: value.target.checked,
          filters,
        })
      );
    }

    if (formName === "consultor") {
      if (value === "") dispatch(setConsultor(false));
      dispatch(setConsultor(value));
    }

    if (formName === "clinica") {
      if (value === "") dispatch(setClinica(false));
      dispatch(setClinica(value));

    }

    if (formName === "especialidad") {
      if (value === "") dispatch(setEspecialidad(false));
      dispatch(setEspecialidad(value));
    }

    if (formName === "asesor") {
      if (value === "") dispatch(setAsesor(false));
      dispatch(setAsesor(value));
    }

    if (formName === "doctor") {
      if (value === "") dispatch(setDoctor(false));
      dispatch(setDoctor(value));
    }

    if (formName === "centro") {
      if (value === ""){
        dispatch(setCentro(false));
        setCurrentClinica(null);
      }else{
        setCurrentClinica(value);
      }
      dispatch(setCentro(value));

    }

  };

  const handleClick = (name: string, selectedName: string) => {
    let today = moment();
    setSelectedName(selectedName);

    if (name === "yesterday") {
      dateInputRef?.current?.blur();
      dispatch(
        getResumen({
          starts: moment(today)
            .subtract(1, "day")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
          ends: moment(today)
            .endOf("month")
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
          last: lastDates,
          filters,
        })
      );
    }

    if (name === "this-month") {
      dateInputRef?.current?.blur();
      dispatch(
        getResumen({
          starts: moment(today)
            .startOf("month")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
          ends: moment(today)
            .endOf("month")
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
          last: lastDates,
          filters,
        })
      );
    }

    if (name === "last-month") {
      dateInputRef?.current?.blur();
      dispatch(
        getResumen({
          starts: moment(today)
            .subtract(1, "month")
            .startOf("month")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
          ends: moment(today)
            .subtract(1, "month")
            .endOf("month")
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
          last: lastDates,
          filters,
        })
      );
    }

    if (name === "last-quarter") {
      dateInputRef?.current?.blur();
      dispatch(
        getResumen({
          starts: moment(today)
            .subtract(3, "month")
            .startOf("month")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
          ends: moment()
            .endOf("month")
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
          last: lastDates,
          filters,
        })
      );
    }

    if (name === "this-year") {
      dateInputRef?.current?.blur();
      dispatch(
        getResumen({
          starts: moment(today)
            .startOf("year")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
          ends: moment(today)
            .endOf("year")
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
          last: lastDates,
          filters,
        })
      );
    }

    if (name === "last-year") {
      dateInputRef?.current?.blur();
      dispatch(
        getResumen({
          starts: moment(today)
            .subtract(12, "month")
            .startOf("year")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
          ends: moment(today)
            .subtract(12, "month")
            .endOf("year")
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
          last: lastDates,
          filters,
        })
      );
    }

    if (name === "next-month") {
      dateInputRef?.current?.blur();
      dispatch(
        getResumen({
          starts: moment(today)
            .add(1, "month")
            .startOf("month")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
          ends: moment(today)
            .add(1, "month")
            .endOf("month")
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 }),
          last: lastDates,
          filters,
        })
      );
    }
  };
  
  useEffect(() => {
    setDateValue(date);
  }, [date]);

  const handleGetResume = () => {
    dispatch(getObjetivoPrevision());
    dispatch(
      getResumen({
        ...date,
        last: lastDates,
        filters,
      })
    );
    setDateValue(date);
  };

  useEffect(() => {
    let end = dateValue?.ends?._d;
    let start = dateValue?.starts?._d;

    if (end && start) {
      if (end !== date?.ends._d) {
        handleGetResume();
      }
      if (start !== date?.starts?._d) {
        handleGetResume();
      }
    }
  }, [dateValue, date, handleGetResume, filters, lastDates]);

  useEffect(() => {
    dispatch(
      getResumen({
        starts: moment(date.starts)
          .startOf("month")
          .set({ hour: 0, minute: 0, second: 0}),
        ends: moment(date.ends)
          .endOf("month")
          .set({ hour: 23, minute: 59, second: 59}),
        last: lastDates,
        filters,
      })
    );
  }, [dispatch, filters, lastDates, location]);

  return (
    <Content>
      <div className="header">
        <div className="content">
          <Select
            placeholder="Consultor"
            style={{ alignItems: "center" }}
            onChange={(value: any) => handleSelect(value, "consultor")}
            // dropdownStyle={{width: 100}}
            dropdownStyle={{ minWidth: "30%" }}
          >
            <Option value=""> ------------- </Option>
            {consultorLists &&
              consultorLists.map((item: any, id: any) => (
                <Option key={id} value={item.id}>
                  {item.name} {item.lastname}
                </Option>
              ))}
          </Select>

          {user && user.role === "Director" ? (
            <Select
              placeholder="Centro"
              style={{ wordWrap: "break-word" }}
              onChange={(value: any) => handleSelect(value, "centro")}
              dropdownStyle={{ minWidth: "30%" }}
              disabled={true}
            >
              {centrosLists &&
                centrosLists.map((item: any, id: any) => (
                  <Option key={id} value={item.id}>
                    {item.clinica_nombre}
                  </Option>
                ))}
            </Select>
          ) : (
            <Select
              placeholder="Centro"
              style={{ wordWrap: "break-word" }}
              onChange={(value: any) => handleSelect(value, "centro")}
              dropdownClassName="dropdown-select"
              dropdownStyle={{ minWidth: "30%" }}
            >
              <Option value=""> ------------- </Option>
              {centrosLists &&
                centrosLists.map((item: any, id: any) => (
                  <Option key={id} value={item.id}>
                    {item.clinica_nombre}
                  </Option>
                ))}
            </Select>
          )}

          <Select
            placeholder="Asesor"
            style={{ wordWrap: "break-word" }}
            onChange={(value: any) => handleSelect(value, "asesor")}
            dropdownStyle={{ minWidth: "30%" }}
          >
            <Option value=""> ------------- </Option>
            {directorLists &&
              directorLists.map((item: any, id: any) =>
                  (currentclinica==null || item.centro_id==currentclinica)?(
                <Option key={id} value={item.id}>
                  {item.name} {item.lastname}
                </Option>
              ):null)}
          </Select>
          <Select
            placeholder="Especialidad"
            style={{ wordWrap: "break-word" }}
            onChange={(value: any) => handleSelect(value, "especialidad")}
            dropdownStyle={{ minWidth: "30%" }}
          >
            <Option value=""> ------------- </Option>
            {especialidadesLists &&
              especialidadesLists.map((item: any, id: any) => (
                <Option key={id} value={item.id}>
                  {item.nombre}
                </Option>
              ))}
          </Select>
          <Select
            placeholder="Doctor"
            onChange={(value: any) => handleSelect(value, "doctor")}
            dropdownStyle={{ minWidth: "30%" }}
          >
            <Option value=""> ------------- </Option>
            {doctorLists &&
              doctorLists.map((item: any, id: any) =>
                  (currentclinica==null || item.centro_id==currentclinica)?(

                <Option key={id} value={item.id}>
                  {item.nombre} {item.apellidos}
                </Option>

              ):null
              )}
          </Select>
          <div style={{ position: "relative" }} className="inner-date">
            <RangePicker
              ref={dateInputRef}
              defaultValue={[
                moment().startOf("month"),
                moment().endOf("month"),
              ]}
              format={dateFormatList}
              size="large"
              onChange={handleChange}
              value={[date.starts, date.ends]}
              panelRender={(PanelNode: any) => {
                let { props } = PanelNode;

                return (
                  <RangeDropdown
                    children={PanelNode}
                    handleClick={handleClick}
                    selectedName={selectedName}
                    useChildren={useChildren}
                    onClick={onClick}
                  />
                );
              }}
              // panelRender={(panelNode: any)=>console.log(panelNode)}
            />
          </div>
          <Checkbox
            style={{ height: "100%", alignItems: "center", display: "flex" }}
            onChange={(value: any) => handleSelect(value, "checkbox")}
            checked={lastDates}
          >
            Fechas anteriores
          </Checkbox>
        </div>
      </div>
    </Content>
  );
}

export default React.memo(IndexSection);

const RangeDropdown = (props: any) => {
  let { children, handleClick, selectedName, useChildren, onClick } = props;

  return (
    <>
      {!useChildren ? (
        <div className="dropdown-range">
          <CustomButton
            selectedName={selectedName}
            label="Personalizar Fechas"
            handleClick={onClick}
          />
          <CustomButton
            selectedName={selectedName}
            label="Ayer"
            handleClick={() => handleClick("yesterday", "Ayer")}
          />
          <CustomButton
            selectedName={selectedName}
            label="Este mes"
            handleClick={() => handleClick("this-month", "Este mes")}
          />
          <CustomButton
            selectedName={selectedName}
            label="Mes anterior"
            handleClick={() => handleClick("last-month", "Mes anterior")}
          />
          <CustomButton
            selectedName={selectedName}
            label="Último trimestre"
            handleClick={() => handleClick("last-quarter", "Último trimestre")}
          />
          <CustomButton
            selectedName={selectedName}
            label="Este año"
            handleClick={() => handleClick("this-year", "Este año")}
          />
          <CustomButton
            selectedName={selectedName}
            label="Año anterior"
            handleClick={() => handleClick("last-year", "Año anterior")}
          />
          <CustomButton
            selectedName={selectedName}
            label="Próximo mes"
            handleClick={() => handleClick("next-month", "Próximo mes")}
          />
        </div>
      ) : (
        children
      )}
    </>
  );
};

const CustomButton = (props: any) => {
  let { handleClick, label, selectedName } = props;

  return (
    <button
      className={
        selectedName === label ? "button-dropdown-active" : "button-dropdown"
      }
      onClick={handleClick}
    >
      {label}
    </button>
  );
};
