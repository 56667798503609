import React, {useState, useEffect, useRef} from 'react'
import { useSelector } from 'react-redux'
import {Modal, Form, Row, Col, Input, Select, InputNumber, Typography, DatePicker} from 'antd'

import FormEuro from './form_euro'

interface Props {
    handleChangeNameValue: any
    data: any
    inputName: string
}

function General(props: Props) {
    const {
        data,
        handleChangeNameValue,
        inputName
    } = props

    const inputAmount = useRef<HTMLInputElement>(null);
    const inputAceptado = useRef<HTMLInputElement>(null);
    const section="general";

    const [inputData, setInputData] = useState({
        presupuesto: 0,
        aceptado: 0,
        doctor: {
            id: '',
            nombre: ''
        }
    })
    const filters = useSelector((state: any)=> state.Filters)
    /*
    useEffect(() => {
        setInputData(data)
    }, [data])*/

    useEffect(() => {
        const last = sessionStorage.getItem(section);

        if(last == "presupuesto") {
            if (inputAmount.current) {
                inputAmount.current.focus();
            }
        }else if(last=="aceptado"){
            if (inputAceptado.current) {
                inputAceptado.current.focus();
            }
        }
    }, []);
    const {doctorLists} = useSelector( (state:any)=>state.admins)
    const {Option} = Select

    const handleChange = (inputCamp:any, value:any) => {
        sessionStorage.setItem(section, inputCamp);
        handleChangeNameValue(inputName, inputCamp, value)
    }

    return (
        <div className="wrapper">
            <Row style={{display: 'grid', gridGap: '.5em', gridTemplateColumns: '33% 33% 32.5%'}}>

                <FormEuro 
                    label='Presupuesto'
                    placeholder='Escribir cantidad'
                    value={data.presupuesto}
                    name='presupuesto'
                    handleChange={(value:any)=>handleChange( 'presupuesto', value)}
                    ref={inputAmount}
                />

                <FormEuro 
                    label='Aceptado'
                    placeholder='Escribir cantidad'
                    value={data.aceptado}
                    name='aceptado'
                    handleChange={(value:any)=>handleChange('aceptado', value)}
                    ref={inputAceptado}
                    />

                <Col span={24}>
                    <Form.Item label='Doctor' labelCol={{span: 24}} wrapperCol={{ span: 24}}>
                        <Select 
                            value={data.empleado_id}
                            onChange={(value:any)=>handleChange('empleado_id', value)}
                            style={{width: '100%'}}>
                            {
                                doctorLists.map( (item:any, id:any)=>
                                    (filters==null || filters.centro==null || !filters.centro || item.centro_id==filters.centro)?(
                                        <Option key={id} value={item.id}>{item.nombre} {item.apellidos}</Option>

                                    ):null)
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export default General
